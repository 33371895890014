<template>
	<div class="page_about">
		<section class="header-bg" style="">
		</section>

		<section class="container-1200 se2" style="margin-top: 40px;">
			<div style="height: 20px;">
				<p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">ABOUT
					</strong></p>
			</div>
		</section>

		<section class="container-1200 se3">
			<!-- <div class="block">&nbsp;Introduction</div> -->
			<div class="introduction">
				<div class="introduction_content">
					<div class="introduction_content_title">What is Contrimetric?</div>
					<div lang="en"
						style="text-align: justify; hyphens: auto;line-height: 28px;font-weight:500;font-size: 16px;">
						<p>Contrimetric is a newly proposed concept of bibliometrics, derived from Contribution Metric.
							It is also demonstrated by a specific indicator called Article Citation Contribution
							Indicator(ACCI).</p>
						<p>The ACCl value is one of the article level metrics and calculated with the
							journal impact factor of each journal as the benchmark.</p>
						<p>When a paper gets a higher citation than the average of all the papers in the same year, it
							has a positive ACCI value of the
							year.</p>
					</div>
				</div>
				<div class="introduction_img">
					<img :src="require('@/assets/images/aboutR.png')" alt="">
				</div>
			</div>
		</section>

		<div class="about_research_box">
			<div class="research_content">
				<div class="research_title">
					<p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">RESEARCHERS
						</strong></p>
				</div>
			</div>
		</div>

		<div class="about_apply">
			<div class="apply_content">
				<div class="apply_box">
					<div class="box_title1">Let's stay in touch</div>
					<div class="box_title2">Explore the ways that we can work together.</div>
				</div>
				<div class="apply_btn">
					<el-button>Apply Membership</el-button>
				</div>
			</div>
		</div>

		<section class="container-1200" style="margin: 40px auto;">
			<div class="block">&nbsp;Q&A</div>
			<CollapseList :collapseList="fqaList" :customClass="customClass"></CollapseList>
		</section>
	</div>

</template>
<script>
import { fqaList } from "./data"
import CollapseList from "@/components/collapseList/index.vue"

export default {
	props: ['searchVal'],
	components: {
		CollapseList,
	},
	data() {
		return {
			fqaList,
			customClass: 'support_collapse',
			aboutShow: true,
			aboutShowA: false,
			aboutShowB: false,
			aboutShowC: false,
			aboutShowD: false,
			aboutShowE: false,
		};
	},
	methods: {

	},

};
</script>

<style lang="scss">
@import "../assets/css/minix.scss";

.page_about {
	.se3 {
		margin-top: 80px;
	}

	.about_research_box {
		display: flex;
		justify-content: center;
		margin-bottom: 200px;

		.research_content {
			@include responsive-width(1200px);
		}
	}

	.about_apply {
		margin-top: 50px;
		width: 100%;
		height: 300px;
		background: url(../assets/images/about_bj.svg) no-repeat;
		background-size: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.apply_content {
			@include responsive-width(1200px);

			.apply_box {
				width: 100%;
				display: flex;
				justify-content: center;
				flex-direction: column;

				.box_title1 {
					font-weight: bold;
					font-size: 32px;
				}

				.box_title2 {
					font-size: 16px;
					margin: 20px 0;
				}
			}
		}
	}

	.support_collapse {
		.el-collapse-item__header {
			font-weight: 700;
			font-size: 16px;
			height: 42px;
			line-height: 42px;
			padding: 8px 35px;
			color: #333;
			background-color: #fafafa;
			margin-top: 20px;
		}

		.el-collapse-item__content {
			padding: 10px 40px;
			line-height: 1.6;
			color: #5f5f5f;
		}
	}

	.introduction {
		display: flex;
	}

	.introduction_content {
		flex: 1;
		padding: 20px;
	}

	.introduction_content_title{
		font-weight:bold;
		font-size:30px;
		margin-bottom:10px;
	}

	.introduction_img {
		flex: 1;
		padding: 20px;

		img {
			margin-left: 100px;
		}
	}

	.block {
		width:100%;
		font-size:30px;
		text-align: center;
		margin-bottom: 20px;
		font-weight: bold;
	}
}

@media (max-width: 768px) {
	.page_about {
		.about_apply {
			background-size: cover;
			background-position-x: 60%;

			.box_title1{
				font-size: 26px!important;
			}
		}

		.introduction_content_title{
			font-size: 26px;
		}

		.container-1200 {
			.introduction_img {
				display: none;
			}
		}

		.se2 {
			margin-left: 20px !important;
		}

		.se3 {
			margin-top: 30px;
		}

		.collapse_box {
			.el-collapse {
				.el-collapse-item {
					.el-collapse-item__header {
						line-height: 1.3;
					}
				}
			}
		}

		.block{
			font-size: 26px;
		}
	}
}
</style>